import { OpenCartButton } from "../../AddToCartButton";
import styles from '../styles.module.css';
import Link from "next/link";
import classNames from "classnames";
import thriftLogoFavicon from '../../../../../public/thriftLogoFavicon.png';
import Image from "next/image";
import { NavLinkStoryblok } from "../../../../../component-types-sb";

interface Props {
    categories: NavLinkStoryblok[];
    selectedTab: string | null;
}

export const StickyHeader: React.FC<Props> = ({ categories, selectedTab }) => {
    if (!categories) return null;

    return (
        <nav className={`${styles.CategoriesHeader} ${styles.Sticky}`}>
            <div className="Container">
                <div className="Section">
                    <div className="flex justify-between">
                        <div className="flex gap-4 items-center">
                            <Image src={thriftLogoFavicon.src} loading="eager" alt="Thrift Logo" width={35} height={20} className={styles.Logo}></Image>
                            <div className={styles.Categories}>
                                {categories.map((category) => (
                                    <Link
                                        key={category.text}
                                        href={category?.url?.url}
                                        className={classNames(
                                            styles.Category,
                                            { [styles.ActiveTab]: category.url?.url === selectedTab }
                                        )}
                                    >
                                        {category.text}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <OpenCartButton />
                    </div>
                </div>
            </div>
        </nav>
    );
}