import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';
import { ChevronDown } from '../../core/Icons/iconList';
import { RewardSelect } from '../../shopify/RewardItem';

interface Props {
  options: RewardSelect[];
  value: string | number;
  onChange: (value: any) => void;
  fullWidth?: boolean;
}

const CustomSelect: React.FC<Props> = ({ options, value, onChange, fullWidth = true }) => {
  const [selectedValue, setSelectedValue] = useState<string | number>(value);

  const handleSelect = (item: RewardSelect | number): void => {
    onChange(item);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedValueAsNumber = isNaN(Number(selectedValue)) ? selectedValue : Number(selectedValue);
    setSelectedValue(selectedValueAsNumber);
    const selectedOption = options.find(option => option.value.toString() === selectedValue);
    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  return (
    <div className={styles.dropdownContainer}>
      <select
        className={classNames('body-12', styles.dropdownButton, { [styles.fullWidth]: fullWidth })}
        value={selectedValue}
        onChange={handleChange}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value} onClick={() => handleSelect(item)}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
