import React, { useContext, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Button, RichText } from '../../../core';
import { ClearoutOrderItemStoryblok } from './types';
import styles from './styles.module.css';
import SignpostLink from '../../../core/SignpostLink';
import { MarketingPreferences } from '../../../account/MarketingPreferences';
import { useCustomer } from '../../../account/Payout/useCustomer';
import classNames from 'classnames';
import Link from 'next/link';
import { useThriftBagCartLine } from '../../../../data-fetching/cart/useCartLine';
import { CheckoutCartContext } from '../../../../context/CheckoutCartContext';

interface Props {
  blok: ClearoutOrderItemStoryblok;
  next: () => void;
  isLastSlide: boolean;
}

const ClearoutOrderItem = ({ blok, next, isLastSlide }: Props) => {
  const { heading, content, cta_text } = blok || {};
  const { addToCart: addThriftBagToCart, isInCart } = useThriftBagCartLine();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const {customer} = useCustomer();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { openCart } = useContext(CheckoutCartContext);

  async function handleClearOut() {
    if (!isChecked) {
      setError('Confirm you accept to use our service.');
      return;
    } else if (!addThriftBagToCart) {
      openCart();
      return;
    } else {
      setIsLoading(true);
      await addThriftBagToCart();
      openCart();
      setIsLoading(false);
    }
  }

  const toggleAcceptConditions = (e) => {
    setIsChecked(e.target.checked);
  }

  return (
    <div className="mt-10 md:mt-0 w-full h-full relative px-6 py-6 flex flex-col items-start overflow-scroll" {...storyblokEditable(blok)}>
      {heading && <h1 className={classNames(styles.title, 'display-18-caps')}>{heading}</h1>}
      {content && <RichText className={styles.content} document={content} />}
      {cta_text && (
        <>
          {isLastSlide ? (
          <>
          <div className="mb-8">
            <input type="checkbox" checked={isChecked} onChange={toggleAcceptConditions}/>
            <span className="ml-1">
              By buying a Thrift+ bag, I agree to Thrift+ <Link href="https://thrift.plus/terms-of-service" className="underline" target="_blank">T&Cs.</Link>
            </span>
          </div>          
          {customer && <MarketingPreferences text={"Subscribe for updates on your bag, how to guides and more."}/>}
          <div className='flex justify-center w-full mt-8'>
            <Button text={cta_text} onClick={handleClearOut} isLoading={isLoading} loadingText={'Adding'} />
          </div>
          </>
          ) : (
            <div className="flex items-center justify-center h-auto w-full md:w-auto">
              <SignpostLink signpostText={cta_text} onClick={next} iconRight={''} />
            </div>
          )}
        </>
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default ClearoutOrderItem;
