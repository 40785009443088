import { categoryDisplayNames, departmentDisplayNames, sizeDisplayString, typeDisplayNames } from '../consts/taxonomyEnums';
import allPublicHolidays from '../consts/bank-holidays.json';

const MONTH_MAP = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;
const FORTY_EIGHT_HOURS_IN_MS = TWENTY_FOUR_HOURS * 2;

function parseCookies(cookies) {
  const cookieObject = {};

  const pairs = cookies.split('; ');
  pairs?.forEach((pair) => {
    const splitPair = pair.trim().split('=');
    if (!splitPair[0] || !splitPair[1]) {
      return;
    }
    cookieObject[splitPair[0].trim()] = splitPair[1].trim();
  });

  return cookieObject;
}

export const makeCoreRequest = async (options) => {
  options.validateStatus = () => true;

  const cookies = parseCookies(document.cookie);

  options.url = process.env.NEXT_PUBLIC_THRIFT_API + options.url;
  options.headers = options.headers || {};
  options.headers['x-customer-auth'] = cookies.customerAccessToken;
  options.headers['content-type'] = 'application/json';

  if (!cookies.customerAccessToken) {
      // No token set, so redirect
      document.cookie = 'customerAccessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      if (window.location.href.indexOf('/account') > -1) {
          window.location.href = '/account/login';
      }
      return null;
  }

  const response = await fetch(options.url, {
      headers: options.headers,
      method: options.method,
      body: JSON.stringify(options.data),
  });

  let body = null;
  try {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json') && response.status !== 204) {
      body = await response.json();
    } else {
      body = undefined; //adding this fallback as we had parsing json error
    }
  } catch (e) {
    console.warn("Failed to parse response body:", e);
  }

  const responseOK = Math.floor(response.status / 100) === 2;
  const reqId = response.headers.get('x-req-id');

  if (response.status === 401) {
      // Delete the cookie
      document.cookie = 'customerAccessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      if (window.location.href.indexOf('/account') > -1) {
          window.location.href = '/account/login';
      }
  }

  return {
      ok: responseOK,
      status: response.status,
      body,
      reqId,
  };
};


export const getPriceDisplayString = (price) => {
  if (typeof price !== 'number' || Number.isNaN(price) || price < 0) {
    return '---';
  }
  return '£' + price.toFixed(2);
};

export const convertISODateToDisplayDate = (date) => {
  const dateObj = new Date(date);
  //Check date is valid
  if (Number.isNaN(dateObj.getTime())) {
    return '---';
  }

  //Check date isn't start of EPOCH
  if (dateObj.getTime() === 0) {
    return '---';
  }

  const dateDay = dateObj.getUTCDate();
  const dateMonth = MONTH_MAP[dateObj.getUTCMonth()];
  const dateYear = dateObj.getUTCFullYear();
  let dateOrdinal = '';

  switch (dateDay) {
    case 1:
      dateOrdinal = 'st';
      break;
    case 2:
      dateOrdinal = 'nd';
      break;
    case 3:
      dateOrdinal = 'rd';
      break;
    case 21:
      dateOrdinal = 'st';
      break;
    case 22:
      dateOrdinal = 'nd';
      break;
    case 23:
      dateOrdinal = 'rd';
      break;
    case 31:
      dateOrdinal = 'st';
      break;
    default:
      dateOrdinal = 'th';
      break;
  }

  return `${dateDay}${dateOrdinal} ${dateMonth} ${dateYear}`;
};

const getSizeDisplayString = {
  chest: (size) => {
    return `Chest: ${size} in`;
  },
  collar: (size) => {
    return `Collar: ${size} in`;
  },
  eu_dress: (size) => {
    return `EU: ${size}`;
  },
  eu_shoe: (size) => {
    return `EU: ${size}`;
  },
  height: (size) => {
    return `Height: ${size} in`;
  },
  italy_dress: (size) => {
    return `Italy: ${size}`;
  },
  leg: (size) => {
    return `Leg: ${size} in`;
  },
  length: (size) => {
    return `Length: ${size} in`;
  },
  lwh: (size) => {
    return `LWH: ${size}`;
  },
  one_size: (size) => {
    return `One Size`;
  },
  sml: (size) => {
    return `SML: ${size.toUpperCase()}`;
  },
  uk_dress: (size) => {
    return `UK: ${size}`;
  },
  uk_shoe: (size) => {
    return `UK: ${size}`;
  },
  us_dress: (size) => {
    return `US: ${size}`;
  },
  us_shoe: (size) => {
    return `US: ${size}`;
  },
  waist: (size) => {
    return `Waist: ${size} in`;
  },
  width: (size) => {
    return `Width: ${size} in`;
  },
};

export const getDisplaySize = (size) => {
  const sizesArray = [];
  Object.keys(size).forEach((sizeType) => {
    if (getSizeDisplayString[sizeType]) {
      sizesArray.push(getSizeDisplayString[sizeType](size[sizeType]));
    }
  });

  if (!sizesArray.length) {
    return '';
  }

  return sizesArray.join('; ');
};

export const deepGetCheck = (obj, path) => {
  if (typeof obj === 'undefined' || obj === null) {
    return false;
  }

  let key = '';

  for (let i = 0, l = path.length; i < l; i++) {
    key = path[i];
    obj = obj[key];
    if (typeof obj === 'undefined' || obj === null) {
      return false;
    }
  }

  return true;
};

export const getDisplayString = (string, type) => {
  switch (type) {
    case 'size':
      return sizeDisplayString[string];
    case 'department':
      return departmentDisplayNames[string];
    case 'category':
      return categoryDisplayNames[string];
    case 'type':
      return typeDisplayNames[string];
    default:
      return '---';
  }
};

export const getSizeOptions = (sizeHeader) => {
  switch (sizeHeader.type) {
    case 'enum':
      return sizeHeader.values.map((value) => {
        return value;
      });
    case 'number':
      return generateNumberArray(sizeHeader.min, sizeHeader.max);
    case 'even_number':
      return generateEvenNumberArray(sizeHeader.min, sizeHeader.max);
    case 'half_number':
      return generateHalfNumberArray(sizeHeader.min, sizeHeader.max);
    default:
      return [];
  }
};

const generateEvenNumberArray = (min, max) => {
  const output = [];
  for (var i = min; i <= max; i += 2) {
    output.push(i);
  }
  return output;
};

const generateNumberArray = (min, max) => {
  const output = [];
  for (var i = min; i <= max; i++) {
    output.push(i);
  }
  return output;
};

const generateHalfNumberArray = (min, max) => {
  const output = [];
  for (var i = min; i <= max; i++) {
    output.push(i);
    i + 0.5 > max ? null : output.push(i + 0.5);
  }
  return output;
};

// function dataURItoBlob(dataURI) {
//   const byteString = window.atob(dataURI);
//   const arrayBuffer = new ArrayBuffer(byteString.length);
//   const int8Array = new Uint8Array(arrayBuffer);
//   for (let i = 0; i < byteString.length; i++) {
//     int8Array[i] = byteString.charCodeAt(i);
//   }
//   const blob = new Blob([int8Array], { type: 'application/pdf' });
//   return blob;
// }

// data should be your response data in base64 format

export const downloadPDF = (base64Data) => {
  const byteString = atob(base64Data);

  const byteArray = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  const buffer = new Uint8Array(byteArray);
  const blob = new Blob([buffer], { type: 'application/pdf' });

  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('target', '_blank');
  a.download = 'thrift-label.pdf';

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  a.addEventListener('click', clickHandler, false);
  a.click();
};

// iso date strings: e.g. "2018-03-30"
const publicHolidayDateStrings = allPublicHolidays['england-and-wales'].events.map((holiday) => holiday.date);

export const getCollectionDates = () => {
  const now = Date.now();

  let maxDaysInTheFuture = 6;
  const daysOffset = 2;
  const dates = [];

  for (let i = daysOffset; i < maxDaysInTheFuture; i++) {
    const currentDate = new Date(now + TWENTY_FOUR_HOURS * i);
    const currentDateString = currentDate.toISOString().slice(0, 10);
    if (currentDate.getUTCDay() === 0) {
      // ignore sundays
    } else if (publicHolidayDateStrings.includes(currentDateString)) {
      // ignore public holidays
    } else {
      dates.push(currentDate.toISOString());
    }
  }

  return dates;
};

// just turns setTimeout into a promise so it can be await-ed
export const wait = (durationInMs) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, durationInMs);
  });
};
